import { Link } from "react-router-dom";
import { BackgroundImage } from "react-image-and-background-image-fade";

import { config } from 'config';
import {useContext} from "react";
import {AppContext} from "../../../../contexts/AppContext";
import {FormattedMessage} from "react-intl";

const SlideItem = ({stream_id, title, title_en, thumbnail, metadata}) => {
    const dataLang = useContext(AppContext)
    return (
        <div className="item height-item">
            <div
                className="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                <div className="gen-carousel-movies-style-3 movie-grid style-3">
                    <div className="gen-movie-contain ">
                        <div className="gen-movie-img">
                            <img
                                src={`${config.cloudfront_url}/${encodeURI(thumbnail)}`}
                                className="gen-movie-image"
                                alt=""
                            />   
                            <div className="gen-movie-action">
                                <Link to={`/teaser/vod/${stream_id}`} className="gen-button">
                                    <i className="fa fa-play"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="gen-info-contain">
                            <div className="gen-movie-info">
                                <h6>
                                    <Link to={`/teaser/vod/${stream_id}`}>{ dataLang.langSpanish ? title : title_en }</Link>
                                </h6>
                            </div>
                            {metadata?.Ponente && <div className="gen-movie-info">
                                <h6 className="ponente-color">
                                    <FormattedMessage
                                        id="prePonente"
                                        defaultMessage="Por "
                                    />{metadata?.Ponente}
                                </h6>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideItem;
